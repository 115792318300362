import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Progress, Spinner, Input, Button } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import moment from 'moment';
import axios from 'axios'
import AppConfig from 'constants/config'
import { capitalize } from "helpers/stringHelper";
import MultiSelect from "components/Common/MultiSelect";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import {Helmet} from "react-helmet";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const Lease = () => {
    const navigate = useNavigate();
    const [leaseData, setLeaseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingClient, setLoadingClient] = useState(false);
    const [options, setOptions] = useState([]);
    const [lesseeClients, setLesseeClients] = useState([]);
    const [lessorClients, setLessorClients] = useState([]);
    const [mode, setMode] = useState('lessee')
    const [search, setSearch] = useState('')
    const [clientAccess, setClientAccess] = useState({});
    const [selectedClients, setSelectedClients] = useState(null)
    const [count, setCount] = useState(0)
    const [page, setPage] = useState(0)
    const [selectedCustomers,setSelectedCustomers] = useState([])
    const [customerOptions, setCustomerOptions] = useState([]);
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [classification, setClassification] = useState('')
    const [leaseType, setLeaseType] = useState('')

    const columns = [
        {
          name: "client_id.customer_id.company",
          label: "Account",
          options: {
              filter: false,
              sort: true,
              customBodyRenderLite:(dataIndex)=>{
                return leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.customer_id && leaseData[dataIndex].client_id.customer_id.company ? leaseData[dataIndex].client_id.customer_id.company : ''
              }
          } 
        },
        {
          name: "client_id.client",
          label: "Client",
          options: {
              filter: false,
              sort: true,
              customBodyRenderLite:(dataIndex)=>(
                <>
                  {leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && !leaseData[dataIndex].client_id.logo ? (
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth:'180px' }}>
                      <div className="avatar-sm">
                        <div className="avatar-title rounded-circle">
                          {leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client.charAt(0)}
                        </div>
                      </div>
                      <div className="client">
                        <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client}</div>
                        <div style={clientAccess[leaseData[dataIndex].client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{leaseData && leaseData[dataIndex].client_id && clientAccess[leaseData[dataIndex].client_id.client] ? clientAccess[leaseData[dataIndex].client_id.client].replace('_', " ").toUpperCase() : ''}</div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth:'180px' }}>
                      <div className="avatar-sm">
                        <img
                          className="rounded-circle avatar-sm"
                          src={leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.logo}
                          alt=""
                        />
                      </div>
                      <div className="client">
                        <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client}</div>
                        <div style={clientAccess[leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client] === "read_only" ? { color: 'orange', fontWeight: 'bold' } : { color: 'green', fontWeight: 'bold' }}>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && clientAccess[leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client] ? clientAccess[leaseData[dataIndex].client_id.client].replace('_', " ").toUpperCase() : ''}</div>
                      </div>
                    </div>
                  )}
                </>
              ),
            }
        },
        {
          label: "Lease name",
          name: "name",
          options: {
              filter: false,
              sort: true,
              customBodyRenderLite:(dataIndex)=>(
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', minWidth:'300px'  }}>
                    <div className="avatar-sm">
                      <button
                        type="button"
                        className="btn rounded-circle avatar-sm bg-theme-color mt-0 ms-0 text-align-center"
                      >
                        {leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                          : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                            : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                              : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                : ((leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Building') || (leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Office')) ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                  : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                    : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
                        }
                      </button>
                    </div>
                    <div className="client">
                      <div>{leaseData && leaseData[dataIndex] ? (leaseData[dataIndex].name).toUpperCase() : ''}</div>
                    </div>
                  </div>
                </>
              ),
            }
        },
        {
          name: "type",
          label: "Type",
          options: {
              filter: false,
              sort: false,
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data}</div>
                </div>
              ),
            }
        },
        {
          name: "enddate",
          label: "Lease term",
          options: {
              filter: false,
              sort: true,
              customBodyRenderLite:(dataIndex)=>{
                let val = progressBarForLeases(leaseData && leaseData[dataIndex]);
                return <div className="mt-2" style={{minWidth:'200px'}}> 
                  <div className="mb-2">
                    <Progress
                      value={val}
                      color={'warning'}
                      className="progress-sm"
                    ></Progress>
                  </div>
                  <div className="">
                    <span className="text-muted mb-0">{moment.utc(leaseData && leaseData[dataIndex] && leaseData[dataIndex].commencement).format('MM/DD/YYYY')}</span> -
                    <span className="text-muted mb-0">{moment.utc(leaseData && leaseData[dataIndex] && leaseData[dataIndex].terminal_end_date).format('MM/DD/YYYY')}</span>
                  </div>
                </div>
              },
          }
        },
        {
          name: "status",
          label: "Status",
          options: {
              filter: false,
              sort: true,
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data}</div>
                </div>
              ),
          }
        },
        {
          name: "classification",
          label: "Classification",
          options: {
              filter: false,
              sort: true,
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data == 'operating' ? 'Operating' : 'Financing'}</div>
                </div>
              ),
          }
        },
        {
          name: "discount_rate",
          label: "Discount rate",
          options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data}%</div>
                </div>
              ),
          }
        },
        {
          name: "state",
          label: "State",
          options: {
              filter: false,
              sort: true,
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data}</div>
                </div>
              ),
          }
        },
        {
          name: "calculation_type",
          label: "Lease Type",
          options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              customBodyRender:(data)=>(
                <div className="table-data-margin">
                  <div>{data ? capitalize(data) : 'Daily'}</div>
                </div>
              ),
            }
        },
        {
          name: "menu",
          label: 'Actions',
          options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              customBodyRenderLite:(dataIndex)=>(
                <div className={leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_processing ? "table-data-margin" : "mt-2"}>
                  {
                    leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_processing ? "Processing" :
                    <Button className="btn btn-sm bg-theme-color text-white" disabled={loading} onClick={()=>{navigate(`/lease/details?${leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}`)}}>
                      View Details
                    </Button>
                  }
                </div>
              ),
            }
        },
    ]
  
    const refreshLeaseData = () => {
      const clients = selectedClients ? selectedClients.map(v => v.value) : []
      const customers = selectedCustomers ? selectedCustomers.map(v => v.value).filter( v => v != 'selectall') : []
      setPage(0)
      getLeaseData({ customers: customers, clients: clients, mode: mode ? mode : 'lessee',search : search, type: type ? type : '', status: status ? status : '', classification: classification ? classification : '', leaseType: leaseType ? leaseType : '',page: 0, perPage: 50 })  
    }  
  
    const progressBarForLeases = (data) => {
      let today = moment(new Date(), 'M/D/YYYY')
      let start = moment(new Date(data.commencement), 'M/D/YYYY')
      let end = moment(new Date(data.terminal_end_date), 'M/D/YYYY')
      let startEndDiff = end.diff(start, 'days');
      let todayEndDiff = end.diff(today, 'days');
      let per = ((startEndDiff - todayEndDiff) / startEndDiff) * 100;
      return Math.round(per)
    }

    const getLeaseData = async (data) => {
      setLeaseData([])
      setLoading(true)
      try {
        let value = await axios.post(`${AppConfig.baseUrl}/super_admin/leases`, data ? data : { customers: [], search:'', clients:[], mode : mode || 'lessee', type: type || '', status: status || '', classification: classification || '', leaseType: leaseType || '',page:0,perPage : 50}, {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          }
        })
        if(value.data.error) {
            toast.error(value.data.title || 'Something went wrong');
        }else{
          if (value && value.data && value.data.allLeases) {
            setLeaseData(value.data.allLeases)
            setCount(value.data.totalCount)
          } else {
            setLeaseData([])
            setCount(0)
          }
        }
      } catch (err) {
        console.log('error in listing lease ->', err)
      }
      setLoading(false)
    }

    const getClient = async (ids) => {
      setLoadingClient(true)
      try {
        let value = await axios.get(`${AppConfig.baseUrl}/super_admin/clients?customers=${ids}`, {
          headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
          }
        })
        if (value.data.error) {
            toast.error(value.data.title || 'Something went wrong');
        }
        else {
          let lessee = [];
          let lessor = [];
          let access = {}
          let review = {}
          for (const d of value.data.data) {
            access = { ...access, [d.client]: d.access_type }
            review = { ...review, [d.client]: d.can_review || false }
            if(d && d.client_type == 'lessor'){
              lessor.push({ label: d.client, value: d._id })
            }else{
              lessee.push({ label: d.client, value: d._id })
            }            
          }
          setClientAccess(access)
          setLesseeClients(lessee)
          setLessorClients(lessor)
          setOptions(mode == 'lessor' ? lessor : lessee)
          
        }
      } catch (error) {
        toast.error(error.message || 'Something went wrong');
      }
      setLoadingClient(false)
    }
  
    const handleChange = (selected) => {
      setSelectedClients(selected)
    }
  
    useEffect(() => {
      getCustomer()
      getLeaseData()
    }, [])

    useEffect(() => {
      if(selectedCustomers && selectedCustomers.length > 0){
        let ids = []
        if(selectedCustomers && selectedCustomers[0] && selectedCustomers[0].value == 'selectall'){
          const customers = selectedCustomers.slice(1)
          ids = customers.map(v => v.value)
        }else{
          ids = selectedCustomers.map(v => v.value)
        }        
        getClient(ids)
      }
    }, [selectedCustomers])

    const handleChangeMode = (value)=>{
      setSearch('')
      setType('')
      setClassification('')
      setLeaseType('')
      setStatus('')
      setSelectedCustomers([])
      setSelectedClients([])
      setMode(value)
      setOptions(value == 'lessor' ? lessorClients : lesseeClients)
    }

    const handleType = (value)=>{
      setType(value)
    }

    const handleStatus = (value)=>{
      setStatus(value)
    }

    const handleClassification = (value)=>{
      setClassification(value)
    }

    const handleLeaseType = (value)=>{
      setLeaseType(value)
    }

    const handleChangeSearch = (value)=>{
      setSearch(value)
    }

    const onTableChange = (page)=>{
      const clients = selectedClients ? selectedClients.map(v => v.value) : []
      const customers = selectedCustomers ? selectedCustomers.map(v => v.value).filter( v => v != 'selectall') : []
      getLeaseData({  customers: customers, clients: clients, mode: mode ? mode : 'lessee',type: type ? type : '', status: status ? status : '', classification: classification ? classification : '', leaseType: leaseType ? leaseType : '',search : search, page: page, perPage: 50 })
    }

    const getCustomer = async() => {
      setLoading(true)
      setCustomerOptions([])
      try {
        let {data:value} = await axios.get(`${AppConfig.baseUrl}/customer/listing`,{
          headers: {
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          }
        })
        let customers = [];
        customers.push({label: 'Select All', value: 'selectall'})
        for(const d of value.custData){
          if(d && d._id){
              customers.push({label: d.customer_id.company, value: d.customer_id._id})
          }
        }
        setCustomerOptions(customers)
      } catch(err){
        console.log('error in customer listing ->',err)
      }   
      setLoading(false)
    }

    const handleCustomer = (selected) =>{
      let oldSelect = selectedCustomers.filter(v => v.value == 'selectall' )
      let newSelect = selected.filter(v => v.value == 'selectall' )
      if(selected.length != customerOptions.length && newSelect && newSelect[0] && selected.length > 1){
        let select = selected.filter(v => v.value != 'selectall')
        setSelectedCustomers(select)
      }else if(newSelect && newSelect[0]){
        setSelectedCustomers(customerOptions)
      }else if(oldSelect && oldSelect[0] && (!newSelect || !newSelect[0])){
        setSelectedCustomers([])
      }else if(selected.length == customerOptions.length - 1 && (!newSelect || !newSelect[0])){
        setSelectedCustomers(customerOptions)
      }else{
        setSelectedCustomers(selected)
      }
    }

    return (      
    <Layout>
    <div className="page-content">
      <Helmet>
        <title>LeaseJava | Leases</title>
      </Helmet>
      <div className="container-fluid">
        <Breadcrumbs title="lease " breadcrumbItem="Leases"/>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ThemeProvider theme={createTheme({components: getMuiTheme()})}>
                <MUIDataTable
                    title={
                        <>
                            <Row className="row">
                                <Col md='2' className="dropdown-p">
                                    <div className="mb-3 ">
                                    <select
                                        value={mode}
                                        disabled={loading || loadingClient}
                                        onChange={(e)=>{handleChangeMode(e.target.value)}}
                                        id="formrow-InputState"
                                        className="form-control"
                                        >
                                        <option name="lessee" value="lessee">Lessee</option>
                                        <option name="lessor" value="lessor">Lessor</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md='3'>
                                    <MultiSelect options={customerOptions} handleChange={handleCustomer} selectedOptions={selectedCustomers} loading={loading} placeholder="Select accounts..." />
                                </Col>
                                <Col md='3'>
                                    <MultiSelect options={options} handleChange={handleChange} selectedOptions={selectedClients} disabled={loadingClient || loading} loading={loadingClient} placeholder="Select clients..." />
                                </Col>
                                <Col md='2' className="pr-0">
                                    <div className="me-2 mb-3">
                                        <div className="position-relative sw">
                                        <Input
                                            type="text"
                                            disabled={loading || loadingClient}
                                            className="form-control"
                                            id="search"
                                            placeholder="Search here ..."
                                            value={search}
                                            onChange={(e) =>{
                                            handleChangeSearch(e.target.value)
                                            }}
                                        />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="row">
                                <Col md='2' className="dropdown-p">
                                <div className="mb-3 ">
                                    <select
                                    value={type}
                                    disabled={loading || loadingClient}
                                    onChange={(e)=>{handleType(e.target.value)}}
                                    id="formrow-InputState"
                                    className="form-control"
                                    >
                                    <option name="type" value="">Type</option>
                                    <option value="Vehicle">Vehicle</option>
                                    <option value="Building">Building</option>
                                    <option value="Land">Land</option>
                                    <option value="Equipment">Equipment</option>
                                    <option value="Office">Office</option>
                                    <option value="Others">Others</option>
                                    </select>
                                </div>
                                </Col>
                                <Col md='3' className="dropdown-p">
                                    <div className="mb-3 ">
                                        <select
                                        value={status}
                                        disabled={loading || loadingClient}
                                        onChange={(e)=>{handleStatus(e.target.value)}}
                                        id="formrow-InputState"
                                        className="form-control"
                                        >
                                        <option name="status" value="">Status</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                        <option value="Modified">Modified</option>
                                        <option value="Terminated">Terminated</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md='3' className="dropdown-p">
                                    <div className="mb-3 ">
                                        <select
                                        value={classification}
                                        disabled={loading || loadingClient}
                                        onChange={(e)=>{handleClassification(e.target.value)}}
                                        id="formrow-InputState"
                                        className="form-control"
                                        >
                                        <option name="classification" value="">Classification</option>
                                        <option name="operating" value="operating">Operating</option>
                                        <option name="financing" value="financing">Financing</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md='2' className="dropdown-p">
                                    <div className="mb-3 ">
                                        <select
                                        value={leaseType}
                                        disabled={loading || loadingClient}
                                        onChange={(e)=>{handleLeaseType(e.target.value)}}
                                        id="formrow-InputState"
                                        className="form-control"
                                        >
                                        <option name="leaseType" value="">Lease Type</option>
                                        <option name="monthly" value="monthly">Monthly</option>
                                        <option name="daily" value="daily">Daily</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col md='2' style={{display:'flex',justifyContent:'space-between',marginBottom:'15px'}}>
                                    <button className="btn btn-lbusers" onClick={() => refreshLeaseData()} disabled={loading || loadingClient} >
                                        {
                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle"></i>
                                        }
                                        Submit
                                    </button>
                                </Col>
                            </Row>
                        </>
                    }
                    data={leaseData}
                    columns={columns}
                    options={{ 
                      serverSide:true,
                      rowsPerPage:50, 
                      page:page,
                      count:count,
                      print:false, 
                      download:false, 
                      filter:false, 
                      pagination : true,
                      viewColumns: true,
                      selectableRows:"none", 
                      search:false, 
                      rowsPerPageOptions:[], 
                      responsive:'scroll',
                      textLabels: {
                        body: {
                            noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                        },
                        viewColumns: {
                        title: "",
                        },
                      },
                      onChangePage:(currentPage)=>{
                        setPage(currentPage)
                        onTableChange(currentPage)
                      }
                    }}
                />
                </ThemeProvider>
              </CardBody>
            </Card>
            </Col>
          </Row>
        </div>
      </div>        
      </Layout>
    )
      
}
export default Lease