import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Modal,Spinner} from "reactstrap"
import {Helmet} from "react-helmet";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { capitalize } from "lodash"
import axios from "axios";
import AppConfig from "constants/config";
import ButtonDropdown from "./ButtonDropdown"
import MultiSelect from "components/Common/MultiSelect";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const Leases = () => {
  const [leaseData, setLeaseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scoreOpen, setScoreOpen] = useState(false);
  const [lease,setLease] = useState({})
  const [cases,setCases] = useState([])
  const [options, setOptions] = useState([]);
  const [count, setCount] = useState({})
  const [check, setCheck] = useState({})
  const [selectedCustomers,setSelectedCustomers] = useState([])
  const [showChecked, setShowChecked] = useState(false)
  const columns = [
    {
      name: "",
      label: "",
      options:{
        sort : true,
        search: false,
        setCellProps: () => ({style: {minWidth: '40px', height: '70px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '40px'}}),
        customBodyRenderLite: (dataIndex) => (
          <div className="table-data-margin">
              <input
                  type="checkbox"
                  style={{height:'20px',width:'20px', marginLeft:'5px'}}
                  name = {leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}
                  onClick={(e)=>{
                    handleChangeFlag(e,leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id)
                  }} 
                  checked={leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_checked}
                  id={leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}
              />
          </div>
        ),
      }
    },
    {
      name: "client_id.customer_id.company",
      label: "Account",
      options:{
        sort : true,
        search: true,
        setCellProps: () => ({style: {minWidth: '200px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
        customBodyRenderLite: (dataIndex) => (
          leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.customer_id && leaseData[dataIndex].client_id.customer_id.company ? leaseData[dataIndex].client_id.customer_id.company : ''
        )
      }
    },
    {
      name: "client_id.client",
      label: "Client",
      options:{
        sort : true,
        search: true,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        customBodyRenderLite: (dataIndex) => (
          <>
            {leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && !leaseData[dataIndex].client_id.logo ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <div className="avatar-title rounded-circle">
                    {leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client.charAt(0)}
                  </div>
                </div>
                <div className="client">
                  <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client}</div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <img
                    className="rounded-circle avatar-sm"
                    src={leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.logo}
                    alt=""
                  />
                </div>
                <div className="client">
                  <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client}</div>
                </div>
              </div>
            )}
          </>
        ),
      }
    },
    {
      label: "Lease name",
      name: "name",
      options:{
        sort : true,
        search: true,
        setCellProps: () => ({style: {minWidth: '250px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
        customBodyRenderLite: (dataIndex) => (
          <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div className="avatar-sm">
                  <button
                    type="button"
                    className="btn rounded-circle avatar-sm bg-theme-color mt-0 ms-0 text-align-center"
                  >
                    {leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i> 
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : ((leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Building') || (leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Office')) ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                      : leaseData && leaseData[dataIndex] && leaseData[dataIndex].type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
                    }
                  </button>
                </div>
                <div className="client">
                  <div>{(leaseData && leaseData[dataIndex] && leaseData[dataIndex].name).toUpperCase()}</div>
                </div>
              </div>
          </>
        ),
      }
    },
    {
      name: "audit_score",
      label: "Audit score",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRenderLite: (dataIndex) => (
          <div className={`table-data-margin ${ leaseData && leaseData[dataIndex] && leaseData[dataIndex].status == 'Terminated' && leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '7/8' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '8/8' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].status == 'Terminated' && leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '10/11' ? 'text-success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score == '11/11' ? 'text-success' : 'text-danger'}`}>
            <div>{leaseData && leaseData[dataIndex] && leaseData[dataIndex].audit_score}</div>
          </div>
        ),
      }
    },
    {
      name: "classification",
      label: "Classification",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease == 'operating' ? 'Operating' : 'Financing'}</div>
          </div>
        ),
      }
    },
    {
      name: "reporting_standard",
      label: "Reporting Standard",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '150px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ?  lease : "FASB ASC 842"}</div>
          </div>
        ),
      }
    },
    {
      name: "calculation_type",
      label: "Lease Type",
      options:{
        sort : false,
        search: true,
        setCellProps: () => ({style: {minWidth: '100px'}}),
        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease ? capitalize(lease) : 'Daily'}</div>
          </div>
        ),
      }
    },
    {
      name: "status",
      label: "Status",
      options:{
        sort : true,
        search: true,
        customBodyRender: (lease) => (
          <div className="table-data-margin">
            <div>{lease}</div>
          </div>
        ),
      }
    },
    {
      name: "menu",
      label: 'Actions',
      options:{
        customBodyRenderLite: (dataIndex) => (
          <div className="mt-2">
            <ButtonDropdown lease={leaseData && leaseData[dataIndex]} setScoreOpen={setScoreOpen} setLease={setLease}/>
          </div>
        ),
      }
    },
  ];

  const handleChangeFlag = async(e,id) => {
    if(e){
      e.preventDefault();
    }
    setLoading(true)
    try {
      let {data} = await axios.post(`${AppConfig.baseUrl}/super_admin/updateFlag`,{is_checked:e.target.checked,id},  {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(!data.error){
        refreshLeaseData(showChecked)
      }else{
        throw new Error(data.title)
      }
    } catch(err){
      console.log('error in flag ->',err)
    }   
    setLoading(false)    
  }
  const toggleScore = () => {
      setScoreOpen(!scoreOpen);
      setLease({})      
  }

  useEffect(()=>{
    setCases(Object.keys(lease.audit_result || {}))
  },[lease])

  useEffect(() => {
      getCustomer()
      getCount()
  }, [])

const getLeaseData = async(data) => {
  setLoading(true)
  setLeaseData([])
  try {
    let {data: value} = await axios.post(`${AppConfig.baseUrl}/super_admin/getBrokenLeases`, data, {
      headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      }
    })
    if(!value.error){
      setLeaseData(value.leases)
    }else{
      throw new Error(value.title)
    }
  } catch(err){
    console.log('error in listing lease ->',err)
  }   
  setLoading(false)
}

const getCount = async() => {
  setLoading(true)
  try {
    let {data: value} = await axios.get(`${AppConfig.baseUrl}/super_admin/brokenLeaseCount`, {
      headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      }
    })
    setCount(value.count ? value.count : 0)
    setCheck(value.checked ? value.checked : 0)
  } catch(err){
    console.log('error in listing lease ->',err)
  }   
  setLoading(false)
}

const getCustomer = async() => {
  setLoading(true)
  setOptions([])
  try {
    let {data:value} = await axios.get(`${AppConfig.baseUrl}/customer/listing`,{
      headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
      }
    })
    let customers = [];
    customers.push({label: 'Select All', value: 'selectall'})
    for(const d of value.custData){
      if(d && d._id){
          customers.push({label: d.customer_id.company, value: d.customer_id._id})
      }
    }
    setOptions(customers)
  } catch(err){
    console.log('error in customer listing ->',err)
  }   
  setLoading(false)
}
const handleChange = (selected) =>{
  let oldSelect = selectedCustomers.filter(v => v.value == 'selectall' )
  let newSelect = selected.filter(v => v.value == 'selectall' )
  if(selected.length != options.length && newSelect && newSelect[0] && selected.length > 1){
    let select = selected.filter(v => v.value != 'selectall')
    setSelectedCustomers(select)
  }else if(newSelect && newSelect[0]){
    setSelectedCustomers(options)
  }else if(oldSelect && oldSelect[0] && (!newSelect || !newSelect[0])){
    setSelectedCustomers([])
  }else if(selected.length == options.length - 1 && (!newSelect || !newSelect[0])){
    setSelectedCustomers(options)
  }else{
    setSelectedCustomers(selected)
  }
}

  const refreshLeaseData = (check) => {
      const customers = selectedCustomers.filter(v => v.value != 'selectall' )
      getLeaseData({customers: customers,checked: check})
  }

  return (
    <Layout>
      <div className="page-content">
       <Helmet>
          <title>LeaseJava | Broken Leases</title>
       </Helmet>
        <div className="container-fluid">
          <Breadcrumbs title="lease " breadcrumbItem="Broken Leases"/>
          <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                    <ThemeProvider theme={createTheme({components: {...getMuiTheme()}})}>
                    <MUIDataTable
                        title={
                          <Row className="row">
                              <Col md='6'>
                                  <MultiSelect options={options} handleChange={handleChange} selectedOptions={selectedCustomers} loading={loading} placeholder="Select accounts for fetching leases..." />
                              </Col>
                              <Col md='6'>
                                  <div className="d-flex">
                                    <div className="me-4">
                                      <button className="btn bg-theme-color" onClick={() => refreshLeaseData(showChecked)} disabled={loading || !selectedCustomers || (selectedCustomers &&  selectedCustomers.length < 1)} >
                                          {
                                              loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i> 
                                          }
                                          Fetch Broken Leases
                                      </button>
                                    </div>
                                    <div className="form-check mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name = "checked"
                                            onChange={(e)=>{
                                                  setShowChecked(e.target.checked)
                                                  refreshLeaseData(e.target.checked)
                                                }
                                              } 
                                            checked={showChecked}
                                            id="checked"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="checked"
                                        >
                                            Show checked leases 
                                        </label>
                                    </div>
                                  </div>
                              </Col>                                    
                          </Row>
                        }
                        data={leaseData}
                        columns={columns}
                        options={{ 
                        rowsPerPage:10, 
                        print:false, 
                        download:false, 
                        filter:false, 
                        pagination : false,
                        viewColumns: false,
                        selectableRows:"none", 
                        search:false, 
                        rowsPerPageOptions:[], 
                        responsive:'scroll',
                        responsive: 'scroll',
                        textLabels: {
                            body: {
                              noMatch: loading ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                            },
                            viewColumns: {
                            title: "",
                            },
                        } 
                        }}
                    />
                    </ThemeProvider>
                    </CardBody>
                </Card>              
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        isOpen={scoreOpen}
        toggle={() => {
            toggleScore();
        }}
        centered
      >
        <div className="modal-header">
            <h5 className="modal-title mt-0">Audit score</h5>
            <button
                type="button"
                onClick={() => {
                  toggleScore();
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
          <ul className="verti-timeline list-unstyled">
            {
              Array.from(Array(Math.ceil(cases.length/2)).keys()).map((v,i)=>
              { 
                return(
                  <Row key={i}>
                    {
                    cases[i*2+0] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">                            
                            { lease.status == 'Terminated' && i*2+0 == 0 ? <i className='bx bx-minus-circle text-secondary font-size-22'></i> : lease.audit_result && lease.audit_result[cases[i*2+0]] ? <i className='bx bx-check-circle text-success font-size-22'></i> : <i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+0]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                    {
                    cases[i*2+1] ?
                      <Col>
                        <li className="event-list">
                          <div className="event-timeline-dot me-3">
                            {lease.audit_result && lease.audit_result[cases[i*2+1]] ? <i className='bx bx-check-circle text-success font-size-22'></i>:<i className='bx bx-x-circle text-danger font-size-22'></i>}
                          </div>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <div>
                                <h5>{cases[i*2+1]}</h5>
                              </div>
                            </div>
                          </div>
                        </li>
                      </Col>
                    : ''
                    }
                  </Row>
              )
              })
            }
            
          </ul>
        </div>
      </Modal>
    </Layout>
    )
}
export default Leases