import React, { useState,useEffect } from "react"
import { Row, Nav, NavItem, NavLink, TabContent, TabPane, Col, Input } from "reactstrap";
import moment from 'moment';
import {commaSeperatedNumber} from "helpers/stringHelper"
import ExcelJS from "exceljs";
import classnames from "classnames";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const NewJournalEntries = (prop) => {
  const [InitialData, setInitialData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [columns,setColumns] = useState([{}])
  const [verticalActiveTab, setverticalActiveTab] = useState("1");
  const [search, setSearch] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [search1, setSearch1] = useState("");
  const [originalData1, setOriginalData1] = useState([]);
  const [modLease, setModLease] = useState('');

  const handleChange = (event, newValue) => {
    setverticalActiveTab(newValue);
};

  useEffect(()=>{
    if(prop.data) {
      const allData = []
      for(const d of prop.data){
        let obj = {
          _id: d._id,
          date:  moment.utc(d.date).format('MM/DD/YYYY'),
          payment_suspense_account_before_commencement: d.payment_suspense_account_before_commencement && Number(Number(d.payment_suspense_account_before_commencement).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.payment_suspense_account_before_commencement) : '',
          rent_deposit: d.rent_deposit && Number(Number(d.rent_deposit).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_deposit) : '',
          rent_payment_suspense_account: d.rent_payment_suspense_account && Number(Number(d.rent_payment_suspense_account).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_payment_suspense_account) : '',
          rou_asset: d.rou_asset && Number(Number(d.rou_asset).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rou_asset) : '',
          lease_liability: d.lease_liability && Number(Number(d.lease_liability).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.lease_liability) : '',
          rent_expense: d.rent_expense && Number(Number(d.rent_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_expense) : '',
          modification: d.modification && Number(Number(d.modification).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.modification) : '',
          impairment: d.impairment && Number(Number(d.impairment).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.impairment) : '',
          transition: d.transition && Number(Number(d.transition).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.transition) : '',
          nonlease: d.nonlease && Number(Number(d.nonlease).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.nonlease) : '',
          amort: d.amort && Number(Number(d.amort).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.amort) : '',
          operating_variable_lease_expense: d.operating_variable_lease_expense && Number(Number(d.operating_variable_lease_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.operating_variable_lease_expense) : '',
          financing_variable_lease_expense: d.financing_variable_lease_expense && Number(Number(d.financing_variable_lease_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.financing_variable_lease_expense) : '',
          interest: d.interest && Number(Number(d.interest).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.interest) : '',
          differed_value: d.differed_value && Number(Number(d.differed_value).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.differed_value) : ''
        }
        allData.push(obj)
      }
      const allRou = prop.leaseDetails && prop.leaseDetails.rouAsset && prop.leaseDetails.rouAsset.length > 0 ? prop.leaseDetails.rouAsset : []
      const allLia = prop.leaseDetails && prop.leaseDetails.leaseLiability && prop.leaseDetails.leaseLiability.length > 0 ? prop.leaseDetails.leaseLiability : []
      let modData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
      let longTermData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term != true) : ''
      let longTermDate = longTermData ? moment(longTermData.modification_date ? longTermData.modification_date : longTermData.commencement).format('YYYY-MM-DD') : !prop.leaseDetails.is_short_term ? moment(prop.leaseDetails.modification_date ? prop.leaseDetails.modification_date : prop.leaseDetails.commencement).format('YYYY-MM-DD') : moment(prop.leaseDetails.terminal_end_date).add(1,'day').format('YYYY-MM-DD')
      const indexLia = allLia && allLia.length > 0 ? allLia.findLastIndex(v => moment(v.date).isBefore(longTermDate)) : 0
      const indexRou = allRou && allRou.length > 0 ? allRou.findLastIndex(v => moment(v.date).isBefore(longTermDate)) : 0
      const stliability = prop.isModified && allRou && allRou.length > 0 && allLia && allLia.length > 0 ? allRou[indexRou >= 0 ? indexRou : 0].ending - allLia[indexLia >= 0 ? indexLia : 0].ending : 0
      const liability = prop.isModified && allLia && allLia.length > 0 ? allLia[indexLia >= 0 ? indexLia : 0].ending : 0
      const rou = prop.isModified && allRou && allRou.length > 0 ? allRou[indexRou >= 0 ? indexRou : 0].ending : 0
      let updatedData = []
      for(const d of allData){
        let obj = {...d}
        if(prop.isModified && !prop.shortTerm && modData && modData.is_short_term){
          if(moment(obj.date).isSameOrAfter(longTermDate)){
            if(moment(moment(obj.date).endOf('M').format('YYYY-MM-DD')).isSame(moment(longTermDate).endOf('M').format('YYYY-MM-DD'))){
              obj.rou_asset = Number(Number(obj.rou_asset + rou).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(obj.rou_asset + rou) : '' 
              obj.lease_liability = Number(Number(obj.lease_liability + (-liability)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(obj.lease_liability + (-liability)) : '' 
              obj.differed_value = Number(Number(-(stliability)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(-(stliability)) : '' 
            }else{
              obj.differed_value = ''
            }
          }else{
            obj.differed_value = Number(Number((obj.rou_asset || 0) + (obj.lease_liability || 0)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(Number(obj.rou_asset || 0) + Number(obj.lease_liability || 0)) : '' 
            obj.rou_asset = ''
            obj.lease_liability = ''
          }
        }else if(prop.shortTerm){
          obj.differed_value = Number(Number((obj.rou_asset || 0) + (obj.lease_liability || 0)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(Number(obj.rou_asset || 0) + Number(obj.lease_liability || 0)) : ''
          obj.rou_asset = ''
          obj.lease_liability = ''
        }
        updatedData.push(obj)
      }
      setInitialData(updatedData)
      setOriginalData(updatedData)
    }
    if(prop.detailData) {
      const allData = []
      for(const d of prop.detailData){
        let obj = {
          _id: d._id,
          description: d.description,
          date:  moment.utc(d.date).format('MM/DD/YYYY'),
          payment_suspense_account_before_commencement: d.payment_suspense_account_before_commencement && Number(Number(d.payment_suspense_account_before_commencement).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.payment_suspense_account_before_commencement) : '',
          rent_deposit: d.rent_deposit && Number(Number(d.rent_deposit).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_deposit) : '',
          rent_payment_suspense_account: d.rent_payment_suspense_account && Number(Number(d.rent_payment_suspense_account).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_payment_suspense_account) : '',
          rou_asset: d.rou_asset && Number(Number(d.rou_asset).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rou_asset) : '',
          lease_liability: d.lease_liability && Number(Number(d.lease_liability).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.lease_liability) : '',
          rent_expense: d.rent_expense && Number(Number(d.rent_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.rent_expense) : '',
          modification: d.modification && Number(Number(d.modification).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.modification) : '',
          impairment: d.impairment && Number(Number(d.impairment).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.impairment) : '',
          transition: d.transition && Number(Number(d.transition).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.transition) : '',
          nonlease: d.nonlease && Number(Number(d.nonlease).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.nonlease) : '',
          amort: d.amort && Number(Number(d.amort).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.amort) : '',
          operating_variable_lease_expense: d.operating_variable_lease_expense && Number(Number(d.operating_variable_lease_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.operating_variable_lease_expense) : '',
          financing_variable_lease_expense: d.financing_variable_lease_expense && Number(Number(d.financing_variable_lease_expense).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.financing_variable_lease_expense) : '',
          interest: d.interest && Number(Number(d.interest).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.interest) : '',
          differed_value: d.differed_value && Number(Number(d.differed_value).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(d.differed_value) : ''
        }
        allData.push(obj)
      }
      const allRou = prop.leaseDetails && prop.leaseDetails.rouAsset && prop.leaseDetails.rouAsset.length > 0 ? prop.leaseDetails.rouAsset : []
      const allLia = prop.leaseDetails && prop.leaseDetails.leaseLiability && prop.leaseDetails.leaseLiability.length > 0 ? prop.leaseDetails.leaseLiability : []
      let modData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
      let longTermData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term != true) : ''
      let longTermDate = longTermData ? moment(longTermData.modification_date ? longTermData.modification_date : longTermData.commencement).format('YYYY-MM-DD') : !prop.leaseDetails.is_short_term ? moment(prop.leaseDetails.modification_date ? prop.leaseDetails.modification_date : prop.leaseDetails.commencement).format('YYYY-MM-DD') : moment(prop.leaseDetails.terminal_end_date).add(1,'day').format('YYYY-MM-DD')
      const indexLia = allLia && allLia.length > 0 ? allLia.findLastIndex(v => moment(v.date).isBefore(longTermDate)) : 0
      const indexRou = allRou && allRou.length > 0 ? allRou.findLastIndex(v => moment(v.date).isBefore(longTermDate)) : 0
      const index = allData && allData.length > 0 ? allData.findLastIndex(v => moment(v.date).isBefore(longTermDate)) : 0
      const stliability = prop.isModified && allLia && allLia.length > 0 && allRou && allRou.length > 0 ? allRou[indexRou >= 0 ? indexRou : 0].ending - allLia[indexLia >= 0 ? indexLia : 0].ending : 0
      const liability = prop.isModified && allLia && allLia.length > 0 ? allLia[indexLia >= 0 ? indexLia : 0].ending : 0
      const rou = prop.isModified && allRou && allRou.length > 0 ? allRou[indexRou >= 0 ? indexRou : 0].ending : 0
      const data = {
        "_id":{"date": moment(longTermDate).format('YYYY-MM'), "description": "Reclass Short Term to Long Term"},
        "date": moment.utc(longTermDate).format('MM/DD/YYYY'),
        "description":"Reclass Short Term to Long Term",
        "payment_suspense_account_before_commencement": '',
        "rent_deposit": '',
        "rent_payment_suspense_account": '',
        "rou_asset": Number(Number(rou).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(rou) : '',
        "lease_liability": Number(Number(-(liability)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(-(liability)) : '',
        "rent_expense": '',
        "modification": '',
        "impairment": '',
        "transition": '',
        "nonlease": '',
        "amort": '',
        "operating_variable_lease_expense": '',
        'financing_variable_lease_expense': '',
        'interest': '',
        "differed_value": Number(Number(-(stliability)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber(-(stliability)) : ''
      }
      let updatedData = []
      for(const d of allData){
        let obj = {...d}
        if(prop.isModified && !prop.shortTerm && modData && modData.is_short_term){          
          if(moment(obj.date).isSameOrAfter(longTermDate)){
            obj.differed_value = ''
          }else{
            obj.differed_value = Number(Number((obj.rou_asset || 0) + (obj.lease_liability || 0)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber((obj.rou_asset || 0) + (obj.lease_liability || 0)) : ''
            obj.rou_asset = ''
            obj.lease_liability = ''
          }
        }else if(prop.shortTerm){
          obj.differed_value = Number(Number((obj.rou_asset || 0) + (obj.lease_liability || 0)).toFixed(2)) != 0 ? prop.currency + " " + commaSeperatedNumber((obj.rou_asset || 0) + (obj.lease_liability || 0)) : ''
          obj.rou_asset = ''
          obj.lease_liability = ''
        }
        updatedData.push(obj)
      }
      if(prop.isModified && !prop.shortTerm && modData && modData.is_short_term){
        updatedData.splice(index+1,0,data)
      }
      setDetailData([...updatedData])
      setOriginalData1([...updatedData])
    }
    if(prop.isModified){
      let data = []
      let modData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term == true) : ''
      let longTermData = prop.leaseDetails && prop.leaseDetails.modifiedLease && prop.leaseDetails.modifiedLease.length > 0 ? prop.leaseDetails.modifiedLease.find(v=>v.is_short_term != true) : ''
      let longTermDate = longTermData ? moment(longTermData.modification_date ? longTermData.modification_date : longTermData.commencement).format('YYYY-MM-DD') : !prop.leaseDetails.is_short_term ? moment(prop.leaseDetails.modification_date ? prop.leaseDetails.modification_date : prop.leaseDetails.commencement).format('YYYY-MM-DD') : moment(prop.leaseDetails.terminal_end_date).add(1,'day').format('YYYY-MM-DD')
      setModLease(modData)
      if(verticalActiveTab == "2"){
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Description',
            name: 'description',
            options: {
              filter: false, 
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          }]
          if(!prop.shortTerm){
            data = [...data, {
              label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
              name: 'rou_asset',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            },
            {
              label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
              name: 'lease_liability',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            }]
          }
          if(prop.shortTerm || (modData && modData.is_short_term)){
            data = [...data, {
              label: `ST Lease Asset-Liability`,
              name: 'differed_value',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '200px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
              }
            }]
          }
          data = [...data, {
            label: 'Interest',
            name: 'interest',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Rent expense',
            name: 'rent_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
            name: 'amort',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data,{ 
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          }
          data = [...data, {
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data, {
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
        ]
      }else{
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          }]
          if(!prop.shortTerm){
            data = [...data, {
            label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
            name: 'rou_asset',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
            name: 'lease_liability',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          }]
        }
        if(prop.shortTerm || (modData && modData.is_short_term)){
          data = [...data, {
            label: `ST Lease Asset-Liability`,
            name: 'differed_value',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
        }
        data = [...data, {
            label: 'Interest',
            name: 'interest',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Rent expense',
            name: 'rent_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
            name: 'amort',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data,{
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          }
          data = [...data,{
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
              data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data,{
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
        ]
      }
      setColumns(data)
    }
    else if(prop.type == 'financing'){
      let data = []
      if(verticalActiveTab == "2"){
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Description',
            name: 'description',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          }]
          if(prop.shortTerm){
            data = [...data, {
              label: `ST Lease Asset-Liability`,
              name: 'differed_value',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '200px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
              }
            }]
          }else{
            data = [...data, {
              label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
              name: 'rou_asset',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            },
            {
              label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
              name: 'lease_liability',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            }]
          }
          data = [...data, {
            label: 'Interest',
            name: 'interest',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
            name: 'amort',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data=[...data,{
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          } 
          data = [...data,{
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data,{
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
        ]
      }else{
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
            }
          }]
          if(prop.shortTerm){
            data = [...data, {
              label: `ST Lease Asset-Liability`,
              name: 'differed_value',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '200px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
              }
            }]
          }else{
            data = [...data, {
              label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
              name: 'rou_asset',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            },
            {
              label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
              name: 'lease_liability',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              },
            }]
          }
          data = [...data, {
            label: 'Interest',
            name: 'interest',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`,
            name: 'amort',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data,{
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          }
          data = [...data,{
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
              data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data,{
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
        ]
      }
      setColumns(data)
    }
    else{
      let data = []
      if(verticalActiveTab == "2"){
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: 'Description',
            name: 'description',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),             
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),             
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),             
            }
          }]
          if(prop.shortTerm){
            data = [...data, {
              label: `ST Lease Asset-Liability`,
              name: 'differed_value',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '200px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
              }
            }]
          }else{
            data = [...data, {
              label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
              name: 'rou_asset',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            },          
            {
              label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
              name: 'lease_liability',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            }]
          }
          data = [...data, {
            label: 'Rent expense',
            name: 'rent_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),             
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data,{
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          }
          data=[...data,{
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
            }
          }]
          if(prop.mode != 'lessor'){
              data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data,{
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
        ]
      }else{
        data = [{
            label: 'Date',
            name: 'date',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`,
            name: 'payment_suspense_account_before_commencement',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),             
            }
          },
          {
            label: 'Rent Deposit',
            name: 'rent_deposit',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`,
            name: 'rent_payment_suspense_account',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),             
            }
          }]
          if(prop.shortTerm){
            data = [...data, {
              label: `ST Lease Asset-Liability`,
              name: 'differed_value',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '200px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '200px'}}),
              }
            }]
          }else{
            data = [...data, {
              label: `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`,
              name: 'rou_asset',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            },
            {
              label: `${prop.mode == 'lessor' ? "Receivable" : "Lease liability"}`,
              name: 'lease_liability',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '150px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '150px'}}),
              }
            }]
          }
          data = [...data, {
            label: 'Rent expense',
            name: 'rent_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '150px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '150px'}}),             
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data, {
              label: 'Operating Variable Lease Expense',
              name: 'operating_variable_lease_expense',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '250px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '250px'}}),
              }
            }]
          }
          data = [...data,{
            label: `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
            name: 'financing_variable_lease_expense',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '250px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '250px'}}),             
            }
          },
          {
            label: 'Modification Loss or Gain',
            name: 'modification',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '200px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '200px'}}),             
            }
          }]
          if(prop.mode != 'lessor'){
            data = [...data, {
              label: 'Impairment',
              name: 'impairment',
              options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
              }
            }]
          }
          data = [...data,{
            label: 'Transition',
            name: 'transition',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),             
            }
          },
          {
            label: 'Non lease',
            name: 'nonlease',
            options: {
              filter: false,
              sort: false,
              setCellProps: () => ({style: {minWidth: '100px'}}),
              setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
            }
          },
        ]
      }
      setColumns(data)
    }
  },[prop.data, prop.type, verticalActiveTab, prop.leaseDetails])

  const handleSearch = (value) => {
    const data = originalData.filter(v => v.date.match(new RegExp(value,"i")) || v.payment_suspense_account_before_commencement.toString().match(new RegExp(value,"i")) || v.rent_deposit.toString().match(new RegExp(value,"i")) || v.rent_payment_suspense_account.toString().match(new RegExp(value,"i")) || v.rou_asset.toString().match(new RegExp(value,"i"))  || v.lease_liability.toString().match(new RegExp(value,"i")) || v.rent_expense.toString().match(new RegExp(value,"i")) || v.operating_variable_lease_expense.toString().match(new RegExp(value,"i"))  || v.financing_variable_lease_expense.toString().match(new RegExp(value,"i")) || v.modification.toString().match(new RegExp(value,"i")) || v.impairment.toString().match(new RegExp(value,"i"))  || v.transition.toString().match(new RegExp(value,"i")) || v.nonlease.toString().match(new RegExp(value,"i")))
    setInitialData(data)
  }

  const handleSearch1 = (value) => {
    const data = originalData1.filter(v => v.date.match(new RegExp(value,"i")) || v.description.match(new RegExp(value,"i")) || v.payment_suspense_account_before_commencement.toString().match(new RegExp(value,"i")) || v.rent_deposit.toString().match(new RegExp(value,"i")) || v.rent_payment_suspense_account.toString().match(new RegExp(value,"i")) || v.rou_asset.toString().match(new RegExp(value,"i"))  || v.lease_liability.toString().match(new RegExp(value,"i")) || v.rent_expense.toString().match(new RegExp(value,"i")) || v.operating_variable_lease_expense.toString().match(new RegExp(value,"i"))  || v.financing_variable_lease_expense.toString().match(new RegExp(value,"i")) || v.modification.toString().match(new RegExp(value,"i")) || v.impairment.toString().match(new RegExp(value,"i"))  || v.transition.toString().match(new RegExp(value,"i")) || v.nonlease.toString().match(new RegExp(value,"i")))
    setDetailData(data)
  }

  const downloadCSVFile = () => {
    var ExcelJSWorkbook = new ExcelJS.Workbook();    
    if(verticalActiveTab == "2"){
      var worksheet = ExcelJSWorkbook.addWorksheet("Detail_Journal_Entries");      
      worksheet.getColumn("A").width = 10
      worksheet.getColumn("B").width = 10
      worksheet.getColumn("C").width = 10
      worksheet.getColumn("D").width = 10
      worksheet.getColumn("E").width = 10
      worksheet.getColumn("F").width = 10
      worksheet.getColumn("G").width = 10
      worksheet.getColumn("H").width = 10
      worksheet.getColumn("I").width = 10
      worksheet.getColumn("J").width = 10
      worksheet.getColumn("K").width = 10
      worksheet.getColumn("L").width = 10
      worksheet.getColumn("M").width = 10
      if(prop.isModified){
        worksheet.getColumn("N").width = 10
        worksheet.getColumn("O").width = 10
        if(prop.mode != 'lessor')(worksheet.getColumn("P").width = 10)
      }else if(prop.type == 'financing'){
        worksheet.getColumn("N").width = 10
        if(prop.mode != 'lessor')(worksheet.getColumn("O").width = 10)
      }else{
        if(prop.mode != 'lessor')(worksheet.getColumn("N").width = 10)
      }

      worksheet.getCell("A1").value = `Date`  
      worksheet.getCell("B1").value = `Description`      
      worksheet.getCell("C1").value = `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`        
      worksheet.getCell("D1").value = `Rent Deposit`        
      worksheet.getCell("E1").value = `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`   
      if(!prop.shortTerm){
        worksheet.getCell("F1").value = `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`        
        worksheet.getCell("G1").value = `${prop.mode == 'lessor' ? "Receivable" : "Lease Liability"}` 
      }
      if(prop.shortTerm || (modLease && modLease.is_short_term)){
        worksheet.getCell(!prop.shortTerm ? "H1" : "F1").value = `ST Lease Asset-Liability`   
      }      
      /* eslint-disable */
      if(prop.isModified){
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "I1" : prop.shortTerm ? "G1" : "H1").value = `Interest`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "J1" : prop.shortTerm ? "H1" : "I1").value = `Rent Expense` 
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "K1" : prop.shortTerm ? "I1" : "J1").value = `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(prop.mode != 'lessor')worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "L1" : prop.shortTerm ? "J1" : "K1").value = `Operating Variable Lease Expense`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "L1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "M1" : prop.shortTerm && prop.mode == 'lessor' ? "J1" : prop.shortTerm || prop.mode == 'lessor' ? 'K1' : "L1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "M1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "N1" : prop.shortTerm && prop.mode == 'lessor' ? "K1" : prop.shortTerm || prop.mode == 'lessor' ? 'L1' : "M1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor') worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "O1" : prop.shortTerm ? "M1" : "N1").value = `Impairment`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "N1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "P1" : prop.shortTerm && prop.mode == 'lessor' ? "L1" : prop.shortTerm ? "N1" : prop.mode == 'lessor' ? 'M1' : "O1").value = `Transition`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "O1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "Q1" : prop.shortTerm && prop.mode == 'lessor' ? "M1" : prop.shortTerm ? "O1" : prop.mode == 'lessor' ? 'N1' : "P1").value = `Non lease`
      }else if(prop.type == 'financing'){
        worksheet.getCell(prop.shortTerm ? "G1" : "H1").value = `Interest`
        worksheet.getCell(prop.shortTerm ? "H1" : "I1").value = `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(prop.mode != 'lessor')(worksheet.getCell(prop.shortTerm ? "I1" : "J1").value = `Operating Variable Lease Expense`)
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'J1' : "K1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'K1' : "L1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor')worksheet.getCell(prop.shortTerm ? "L1" : "M1").value = `Impairment`
        worksheet.getCell(prop.shortTerm ? "M1" : prop.mode == 'lessor' ? 'L1' : "N1").value = `Transition`
        worksheet.getCell(prop.shortTerm ? "N1" : prop.mode == 'lessor' ? 'M1' : "O1").value = `Non lease`
      }else{
        worksheet.getCell(prop.shortTerm ? "G1" : "H1").value = `Rent Expense` 
        if(prop.mode != 'lessor')(worksheet.getCell(prop.shortTerm ? "H1" : "I1").value = `Operating Variable Lease Expense`)
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'I1' : "J1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'J1' : "K1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor')worksheet.getCell(prop.shortTerm ? "K1" : "L1").value = `Impairment`
        worksheet.getCell(prop.shortTerm ? "L1" : prop.mode == 'lessor' ? 'K1' : "M1").value = `Transition`
        worksheet.getCell(prop.shortTerm ? "M1" : prop.mode == 'lessor' ? 'L1' : "N1").value = `Non lease`
      }
     
      if(prop.isModified){
        detailData && detailData.length > 0 && detailData.map((value, i) => {
          if(!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.differed_value, value.interest, value.rent_expense, value.amort,value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(!prop.shortTerm && (modLease && modLease.is_short_term)){
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.differed_value, value.interest, value.rent_expense, value.amort, value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }else if(prop.shortTerm && prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,(value && (value.rou_asset || value.lease_liability)  ? Number(value.rou_asset + value.lease_liability) : ''), value.interest, value.rent_expense, value.amort,value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,(value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : ''), value.interest, value.rent_expense, value.amort, value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.rent_expense, value.amort,value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.rent_expense, value.amort, value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }
        })
      }else if(prop.type == 'financing'){
        detailData && detailData.length > 0 && detailData.map((value, i) => {
          if(prop.shortTerm && prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.interest, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.interest, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }          
        })
      }else{
        detailData && detailData.length > 0 && detailData.map((value, i) => {
          if(prop.shortTerm && prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.rent_expense, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account,value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.rent_expense,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]); 
          }else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value && value.description && value.description == "Initial Recognition of Lease Liability" ? "Initial Recognition of Lease" : value && value.description && value.description == "Finance Lease Rent Expense" ? "Finance Lease Income" : value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.rent_expense, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value && value.description ? value.description : '', value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.rent_expense,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]); 
          }          
        })
      } 

      worksheet.getColumn(1).numFmt = 'mm/dd/yyyy'
      worksheet.getColumn( 3 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 4 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 5 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 6 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 7 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 8 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 9 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 12).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 13).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 14).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 15).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 16).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 17).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 18).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      if(prop.isModified){
        worksheet.getColumn( 19).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        worksheet.getColumn( 20).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        if(prop.mode != 'lessor')(worksheet.getColumn( 21).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }else if(prop.type == 'financing'){
        worksheet.getColumn( 19).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        if(prop.mode != 'lessor')(worksheet.getColumn( 20).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }else{
        if(prop.mode != 'lessor')(worksheet.getColumn( 19).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }

      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        var csv_file, download_link;
        csv_file = new Blob([buffer], { type: "application/octet-stream" });
        download_link = document.createElement("a");
        download_link.download = "Detail_Journal_Entries.xlsx" ;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = "none";
        document.body.appendChild(download_link);
        download_link.click();
      });

    }else{
      var worksheet = ExcelJSWorkbook.addWorksheet("Journal_Entries");
      worksheet.getColumn("A").width = 10
      worksheet.getColumn("B").width = 10
      worksheet.getColumn("C").width = 10
      worksheet.getColumn("D").width = 10
      worksheet.getColumn("E").width = 10
      worksheet.getColumn("F").width = 10
      worksheet.getColumn("G").width = 10
      worksheet.getColumn("H").width = 10
      worksheet.getColumn("I").width = 10
      worksheet.getColumn("J").width = 10
      worksheet.getColumn("K").width = 10
      worksheet.getColumn("L").width = 10
      if(prop.isModified){
        worksheet.getColumn("M").width = 10
        worksheet.getColumn("N").width = 10
        if(prop.mode != 'lessor')(worksheet.getColumn("O").width = 10)
      }else if(prop.type == 'financing'){
        worksheet.getColumn("M").width = 10
        if(prop.mode != 'lessor')(worksheet.getColumn("N").width = 10)
      }else{
        if(prop.mode != 'lessor')(worksheet.getColumn("M").width = 10)
      }

      worksheet.getCell("A1").value = `Date`       
      worksheet.getCell("B1").value = `${prop.mode == 'lessor' ? 'Receipt Suspense Account Before Commencement' : 'Payment Suspense Account Before Commencement'}`        
      worksheet.getCell("C1").value = `Rent Deposit`        
      worksheet.getCell("D1").value = `${prop.mode == 'lessor' ? "Rent Receipt Suspense Account" : "Rent Payment Suspense Account"}`  
      if(!prop.shortTerm){     
        worksheet.getCell("E1").value = `${prop.mode == 'lessor' ? "Deferred inflow of resources" : "Rou Asset"}`        
        worksheet.getCell("F1").value = `${prop.mode == 'lessor' ? "Receivable" : "Lease Liability"}`   
      }
      if(prop.shortTerm || (modLease && modLease.is_short_term)){
        worksheet.getCell(!prop.shortTerm ? "G1" : "E1").value = `ST Lease Asset-Liability`  
      }
      if(prop.isModified){
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "H1" : prop.shortTerm ? "F1" : "G1").value = `Interest`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "I1" : prop.shortTerm ? "G1" : "H1").value = `Rent Expense` 
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "J1" : prop.shortTerm ? "H1" : "I1").value = `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(prop.mode != 'lessor')(worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "K1" : prop.shortTerm ? "I1" : "J1").value = `Operating Variable Lease Expense`)
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "K1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "L1" : prop.shortTerm && prop.mode == 'lessor' ? 'I1' : prop.shortTerm || prop.mode == 'lessor' ? 'J1' : "K1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "L1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "M1" : prop.shortTerm && prop.mode == 'lessor' ? 'J1' : prop.shortTerm || prop.mode == 'lessor' ? 'K1' : "L1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor')worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term)) ? "N1" : prop.shortTerm ? "L1" : "M1").value = `Impairment`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "M1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "O1" : prop.shortTerm && prop.mode == 'lessor' ? 'K1' : prop.shortTerm ? "M1" : prop.mode == 'lessor' ? 'L1' : "N1").value = `Transition`
        worksheet.getCell((!prop.shortTerm && (modLease && modLease.is_short_term) && prop.mode == 'lessor') ? "N1" : (!prop.shortTerm && (modLease && modLease.is_short_term)) ? "P1" : prop.shortTerm && prop.mode == 'lessor' ? 'L1' : prop.shortTerm ? "N1" : prop.mode == 'lessor' ? 'M1' : "O1").value = `Non lease`
      }else if(prop.type == 'financing'){
        worksheet.getCell(prop.shortTerm ? "F1" : "G1").value = `Interest`
        worksheet.getCell(prop.shortTerm ? "G1" : "H1").value = `${prop.mode == 'lessor' ? 'Lease Revenue' : 'Amort'}`
        if(prop.mode != 'lessor')(worksheet.getCell(prop.shortTerm ? "H1" : "I1").value = `Operating Variable Lease Expense`)
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'I1' : "J1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'J1' : "K1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor')worksheet.getCell(prop.shortTerm ? "K1" : "L1").value = `Impairment`
        worksheet.getCell(prop.shortTerm ? "L1" : prop.mode == 'lessor' ? 'K1' : "M1").value = `Transition`
        worksheet.getCell(prop.shortTerm ? "M1" : prop.mode == 'lessor' ? 'L1' : "N1").value = `Non lease`
      }else{
        worksheet.getCell(prop.shortTerm ? "F1" : "G1").value = `Rent Expense` 
        if(prop.mode != 'lessor')(worksheet.getCell(prop.shortTerm ? "G1" : "H1").value = `Operating Variable Lease Expense`)
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'H1' : "I1").value = `${prop.mode == 'lessor' ? 'Financing Variable Lease Revenue' : 'Financing Variable Lease Expense'}`,
        worksheet.getCell(prop.shortTerm || prop.mode == 'lessor' ? 'I1' : "J1").value = `Modification Loss or Gain`
        if(prop.mode != 'lessor')worksheet.getCell(prop.shortTerm ? "J1" : "K1").value = `Impairment`
        worksheet.getCell(prop.shortTerm ? "K1" : prop.mode == 'lessor' ? 'J1' : "L1").value = `Transition`
        worksheet.getCell(prop.shortTerm ? "L1" : prop.mode == 'lessor' ? 'K1' : "M1").value = `Non lease`
      }

      if(prop.isModified){
        InitialData && InitialData.length > 0 && InitialData.map((value, i) => {
          if(!prop.shortTerm && (modLease && modLease.is_short_term) || prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.differed_value, value.interest, value.rent_expense, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(!prop.shortTerm && (modLease && modLease.is_short_term)){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.differed_value, value.interest, value.rent_expense, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }else if(prop.shortTerm || prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.interest, value.rent_expense, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability): '', value.interest, value.rent_expense, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.rent_expense, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.rent_expense, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }          
        })
      }else if(prop.type == 'financing'){
        InitialData && InitialData.length > 0 && InitialData.map((value, i) => {
          if(prop.shortTerm || prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.interest, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.interest, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          } else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.amort, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.interest, value.amort,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]);
          }          
        })
      }else{
        InitialData && InitialData.length > 0 && InitialData.map((value, i) => {
          if(prop.shortTerm || prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.rent_expense, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else if(prop.shortTerm){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value && (value.rou_asset || value.lease_liability) ? Number(value.rou_asset + value.lease_liability) : '', value.rent_expense,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]); 
          }else if(prop.mode == 'lessor'){
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.rent_expense, value.financing_variable_lease_expense, value.modification, value.transition, value.nonlease]);
          }else{
            worksheet.addRow([ value.date, value.payment_suspense_account_before_commencement, value.rent_deposit, value.rent_payment_suspense_account, value.rou_asset, value.lease_liability, value.rent_expense,  value.operating_variable_lease_expense, value.financing_variable_lease_expense, value.modification, value.impairment, value.transition, value.nonlease]); 
          }
        })
      } 

      worksheet.getColumn(1).numFmt = 'mm/dd/yyyy'
      worksheet.getColumn( 2 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 3 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 4 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 5 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 6 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 7 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 8 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 11 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 12 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 13 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 14 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 15 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 16 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      worksheet.getColumn( 17 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
      if(prop.isModified){
        worksheet.getColumn( 18 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        worksheet.getColumn( 19 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        if(prop.mode != 'lessor')(worksheet.getColumn( 20 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }else if(prop.type == 'financing'){
        worksheet.getColumn( 18 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`
        if(prop.mode != 'lessor')(worksheet.getColumn( 19 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }else{
        if(prop.mode != 'lessor')(worksheet.getColumn( 18 ).numFmt = `"${prop.currency}"##,##,##,##,##0.00`)
      }

      ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
        var csv_file, download_link;
        csv_file = new Blob([buffer], { type: "application/octet-stream" });
        download_link = document.createElement("a");
        download_link.download = "Journal_Entries.xlsx" ;
        download_link.href = window.URL.createObjectURL(csv_file);
        download_link.style.display = "none";
        document.body.appendChild(download_link);
        download_link.click();
      });

    }    
  }
    /* eslint-enable */
  return (
    <React.Fragment>
      <Box>
        <ThemeProvider
          theme={createTheme({
            components: {
              MuiTab: {
                styleOverrides: {
                  root: {
                    fontFamily: '"Poppins",sans-serif',
                    fontSize: "0.8125rem",
                    fontWeight: 400,
                    color: "#495057",
                    letterSpacing: 0,
                    textTransform: 'unset',
                    minHeight: '40px',
                    padding: 0,
                    borderRadius: '5px',
                    "&:hover": {
                      color: "#005691",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#005691",
                      color: "#fff",
                    }
                  },
                },
              },
              MuiTabPanel: {
                styleOverrides:{
                  root: {
                    padding: 0
                  }
                }
              }
            },
          })}
        >
          <TabContext value={verticalActiveTab}>
            <Row>
              <Col sm="4" md="3" lg="2">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    orientation="vertical"
                    variant="scrollable"
                    indicatorColor="#fff"
                  >
                    <Tab label="Monthly aggregate" value="1" />
                    <Tab
                      label={
                        <>
                          Monthly detailed
                          <span
                            className="d-none d-sm-block"
                            style={{ fontSize: "11px" }}
                          >
                            (Experimental)
                          </span>
                        </>
                      }
                      value="2"
                    />
                  </TabList>
                </Box>
              </Col>
              <Col sm="8" md="9" lg="10">
                <TabPanel value="1">
                  <ThemeProvider
                    theme={createTheme({
                      components: {
                        ...getMuiTheme(),
                        MUIDataTableToolbar: {
                          styleOverrides: {
                            actions: {
                              display: "contents",
                            },
                          },
                        },
                      },
                    })}
                  >
                    <MUIDataTable
                      title={
                        <Row className="row">
                          <Col className="search-p">
                            <div className="ms-2 me-2 mb-3">
                              <div className="position-relative sw">
                                <Input
                                  placeholder="Search"
                                  type="text"
                                  value={search}
                                  onChange={(e) => {
                                    handleSearch(e.target.value);
                                    setSearch(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col></Col>
                          <Col>
                            <div
                              className="d-flex"
                              style={{ float: "right" }}
                            >
                              <button
                                type="button"
                                disabled={prop.loading}
                                onClick={() => {
                                  downloadCSVFile();
                                }}
                                className="btn btn-lbusers btn-md"
                              >
                                {prop.loading && (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                )}
                                EXPORT CSV
                              </button>
                            </div>
                          </Col>
                        </Row>
                      }
                      data={InitialData}
                      columns={columns}
                      options={{
                        rowsPerPage: 50000, //InitialData.length/50000
                        print: false,
                        download: false,
                        filter: false,
                        selectableRows: "none",
                        search: false,
                        rowsPerPageOptions: [],
                        tableBodyHeight: "370px",
                        textLabels: {
                          body: {
                            noMatch: ""
                          },
                          viewColumns: {
                            title: "",
                          },
                        },
                        rowHover: false,
                        setRowProps: (row, dataIndex, rowIndex) => {
                          return {
                            style: {
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#ffffff" : "#eeeeee",
                            },
                          };
                        },
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
                <TabPanel value="2">
                  <ThemeProvider
                    theme={createTheme({
                      components: {
                        ...getMuiTheme(),
                        MUIDataTableToolbar: {
                          styleOverrides: {
                            actions: {
                              display: "contents",
                            },
                          },
                        },
                      },
                    })}
                  >
                    <MUIDataTable
                      title={
                        <Row className="row">
                          <Col className="search-p">
                            <div className="ms-2 me-2 mb-3">
                              <div className="position-relative sw">
                                <Input
                                  placeholder="Search"
                                  type="text"
                                  value={search1}
                                  onChange={(e) => {
                                    handleSearch1(e.target.value);
                                    setSearch1(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col></Col>
                          <Col>
                            <div
                              className="d-flex"
                              style={{ float: "right" }}
                            >
                              <button
                                type="button"
                                disabled={prop.loading}
                                onClick={() => {
                                  downloadCSVFile();
                                }}
                                className="btn btn-lbusers btn-md"
                              >
                                {prop.loading && (
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                )}
                                EXPORT CSV
                              </button>
                            </div>
                          </Col>
                        </Row>
                      }
                      data={detailData}
                      columns={columns}
                      options={{
                        rowsPerPage: 50000, //InitialData.length/50000
                        print: false,
                        download: false,
                        filter: false,
                        selectableRows: "none",
                        search: false,
                        rowsPerPageOptions: [],
                        tableBodyHeight: "370px",
                        textLabels: {
                          body: {
                            noMatch: ""
                          },
                          viewColumns: {
                            title: "",
                          },
                        },
                        rowHover: false,
                        setRowProps: (row, dataIndex, rowIndex) => {
                          return {
                            style: {
                              backgroundColor:
                                rowIndex % 2 === 0 ? "#ffffff" : "#eeeeee",
                            },
                          };
                        },
                      }}
                    />
                  </ThemeProvider>
                </TabPanel>
              </Col>
            </Row>
          </TabContext>
        </ThemeProvider>
      </Box>
    </React.Fragment>
  );
}

export default NewJournalEntries;
