import React, {useEffect} from "react"
import { Navigate, Route, Routes } from "react-router-dom"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import moment from "moment"
import { useIdleTimer } from "react-idle-timer"
// Import scss
import "./assets/scss/theme.scss"

import Register from "pages/Authentication/Register"
import FactorAuth from "pages/Authentication/FactorAuth"
import ForgetPwd from "pages/Authentication/ForgetPassword"
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Dashboard from "pages/Dashboard"
import Accounts from "pages/Accounts/account"
import AddAccount from "pages/Accounts/addAccount"
import ActivityLog from "pages/Users/activityLog"
import EditAccount from "pages/Accounts/editAccount"
import AccountDetails from "pages/Accounts/accountDetails"
import Modules from "pages/Accounts/modules"
import UserDetails from "pages/Users/userDetails"
import BillingLogs from "pages/BillingLogs"
import Leases from "pages/Lease"
import LeaseDetails from "pages/Lease/leaseDetails"
import RandomSampling from "pages/RandomSampling"
import BrokenLeases from "pages/Lease/brokenLeases"
import Payment from "pages/Accounts/payment"
import AccountBilling from "pages/Accounts/accountBilling"
import Lease from "pages/Leases"
import UserProfile from "pages/Authentication/user-profile"
import Success from "pages/Payments/Success"
import Failure from "pages/Payments/Failure"
import ResetPassword from "pages/Authentication/resetPassword"

const App = () => {
  const {getLastActiveTime} = useIdleTimer()

  useEffect(() => {
    const lastActive = localStorage.getItem('last_active')
    if(moment().diff(moment(lastActive), 'minutes') > 30){
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login"
    }
    setInterval(() => {
      localStorage.setItem("last_active", getLastActiveTime())
    }, 10000)
  },[])

  function getLayout() {
    let layoutCls = VerticalLayout
    return layoutCls
  }
  const Layout = getLayout()
  return (
    <React.Fragment>
      <Routes>
      {/* Dashboard */}
      <Route path = "/dashboard" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Dashboard/> }/>

      {/* Accounts */}
      <Route path = "/accounts" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Accounts/>}/>
      <Route path = "/accounts/add" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <AddAccount/>}/>
      <Route path = "/accounts/users/activitylog/:id" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <ActivityLog/>}/>
      <Route path = "/accounts/edit" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <EditAccount/>}/>
      <Route path = "/accounts/details" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <AccountDetails/>}/>
      <Route path = "/accounts/modules" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Modules/>}/>
      <Route path = "/accounts/users/details" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <UserDetails/>}/>
      <Route path = "/accounts/payments" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Payment/>}/>
      <Route path = "/accounts/billing" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <AccountBilling/>}/>

      {/* Billing Logs */}
      <Route path = "/billing_logs" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <BillingLogs/>}/>

      {/* lease */}
      <Route path =  "/lease" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Leases/>}/>
      <Route path =  "/broken_leases" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <BrokenLeases/>}/>
      <Route path =  "/lease/details" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <LeaseDetails/>}/>

      {/* Random Sampling */}
      {/* <Route path =  "/random_sampling" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <RandomSampling/>}/> */}

      {/* Leases */}
      <Route path =  "/lease_listing" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Lease/>}/>
      
      {/* User Profile */}
      <Route path =  "/profile" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <UserProfile/>}/>
      
      {/* Payment */}
      <Route path =  "/success" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Success/>}/>
      <Route path =  "/failed" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Failure/>}/>
      
      {/* publicRoutes */}
      <Route path = "/logout" element = { <Logout/> }/>
      <Route path = "/login" element = { <Login/> }/>
      <Route path = "/forgot-password" element = { <ForgetPwd/> }/>
      <Route path = "/factorauth" element = { <FactorAuth/> }/>
      <Route path = "/register" element = { <Register/> }/>
      <Route path = "/reset_password" element = { <ResetPassword/> }/>

      <Route path = "/" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Navigate to="/dashboard" /> }/>
      <Route path = "/:any" element = { !localStorage.getItem("token") ? <Navigate to="/login"/> : <Navigate to="/dashboard" /> }/>

        {/* <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch> */}
      </Routes>
    </React.Fragment>
  )
}


export default App
