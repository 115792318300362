import React from "react"
import {
    Card,
    CardBody,
    Col,
    Form,
    Button,
    Row,
    Alert
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";

const summary = (prop) => {
    let { data } = prop;

    return (
        <React.Fragment>
            <Form>
                { data && data.map((d, i) => <><Row className="mt-3">
                    <Col xs="12">
                        <Alert color="warning" className="text-black" role="alert">
                            <div className="d-flex justify-content-between">
                                <div className="my-2">{(d.name).toUpperCase()}</div>
                                <div>
                                    <Button className="btn btn-lbusers" onClick={()=>{
                                        window.open(`/lease/details?${d._id}`, '_blank')
                                    }}>
                                        VIEW
                                    </Button>
                                </div>
                            </div>
                        </Alert>
                    </Col>
                </Row>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardBody style={{ paddingTop: '28px' }}>
                                    <Row>
                                        {
                                            i < data.length -1 ? 
                                            <>
                                                <Col xs="8">
                                                    <h6>Lease Modification Date:</h6>
                                                </Col>
                                                <Col xs="4" style={{ textAlign: 'right' }}>
                                                    {d && d.modification_date ? moment.utc(d.modification_date).format('MM/DD/YYYY') : ''}
                                                </Col>
                                            </>
                                            :
                                            <>
                                                <Col xs="8">
                                                    <h6>Lease Commencement Date:</h6>
                                                </Col>
                                                <Col xs="4" style={{ textAlign: 'right' }}>
                                                    {d && d.commencement ? moment.utc(d.commencement).format('MM/DD/YYYY') : ''}
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <h6>Lease End Date:</h6>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {d && d.terminal_end_date ? moment.utc(d.terminal_end_date).format('MM/DD/YYYY') : ''}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <h6>GAAP - Lease Classification:</h6>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            <span style={{textTransform: 'capitalize'}}>{d && d?.classification}</span>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardBody style={{ paddingTop: '28px' }}>
                                    <Row>
                                        <Col xs="8">
                                            <h6>Discount Rate:</h6>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {d && d?.discount_rate}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <h6>Type:</h6>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {d.type && (d.type).toUpperCase()}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="8">
                                            <h6>State:</h6>
                                        </Col>
                                        <Col xs="4" style={{ textAlign: 'right' }}>
                                            {d && d.state }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>)}
            </Form>
        </React.Fragment>
    )
}

export default summary