import React, { useState } from "react"
import { ButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu} from "reactstrap";
import toast from 'react-hot-toast';
import axios from 'axios';
import AppConfig from "constants/config";

const DownloadDropdown = (prop) => {
    const { lease_id, from } = prop;
    const [csvDropdown, setCsvDropdown] = useState(false);
    const [downloadingCSV, setDownloadingCSV] = useState(false);

    const handleExportCsv = async (type) => {
        setDownloadingCSV(true)
        try {
            let {data:value} = await axios.get(`${AppConfig.baseUrl}/lease/export_details_csv?lease_id=${lease_id}&type=${type}`, {
                headers: { token: localStorage.getItem("token") },
                responseType: 'blob'
            })
            if (!value.error) {
                const url = window.URL.createObjectURL(new Blob([value]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `lease_details.xlsx`);
                document.body.appendChild(link);
                link.click();
                toast.success('CSV downloaded successfully');
            }
            else {
                throw new Error(value.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setDownloadingCSV(false)
    }
    return (
        <ButtonDropdown
            isOpen={csvDropdown}
            toggle={() => setCsvDropdown(!csvDropdown)}
            className = 'mb-4'
        >
            <DropdownToggle
                disabled={downloadingCSV}
                caret
                className={`btn ${from == 'details' ? 'btn-md  bg-theme-color' : 'btn-xs btn-transparent'}`}
            >
                { from == 'details' ? 'EXPORT CSV' : '' }<i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem className="text-success text-black" onClick={() => handleExportCsv('daily')}> Daily {from != 'details' ? 'Export' : ''}</DropdownItem>
                <DropdownItem className="text-danger text-black" onClick={() => handleExportCsv('monthly')}> Monthly {from != 'details' ? 'Export' : ''}</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default DownloadDropdown